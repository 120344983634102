import React from 'react';
import { Card } from 'react-bootstrap';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

function formatDate(date) {
  if (!date) return '';
  return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_FULL);
}

const Intro = ({ data }) => {
  return (
    <Card className="h-100">
      <Card.Body className="overflow-hidden p-lg-6">
        <p className="lead mb-1">Automated Project Coordinator</p>
        <h3 className="text-primary mb-3">Dashboard</h3>
        <p>
          Basic statistics go here, but we might break some out onto a separate
          "analytics" page in the future.
        </p>
        <p>Coming soon:</p>
        <ul>
          <li>Rates by month (to show trends)</li>
        </ul>
        <hr />
        <h6>Last Updated</h6>
        <p className="fs--1">{formatDate(data?.lastUpdated)}</p>
        <h6>Last Text Sent</h6>
        <p className="fs--1">{formatDate(data?.lastTextSent)}</p>
      </Card.Body>
    </Card>
  );
};

Intro.propTypes = {
  data: PropTypes.shape({
    lastUpdated: PropTypes.string,
    lastTextSent: PropTypes.string
  })
};

export default Intro;
