import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FalconCardHeader from 'components/common/FalconCardHeader';

const OvFailures = ({ data }) => {
  return (
    <Card className="h-100">
      <FalconCardHeader
        title="Order Verified Failures"
        light
        titleTag="h5"
        className="mb-0 pb-0"
      />
      <Card.Footer
        className="bg-100 fs--1 pt-1 pb-2"
        style={{ fontStyle: 'italic' }}
      >
        Order Verified activities that have been completed in the last 20 days
        but not by APC
      </Card.Footer>

      <Card.Body className="overflow-hidden p-lg-3">
        <ul>
          {data.rows &&
            data.rows.map((row, index) => (
              <li key={index}>
                <a
                  href={`https://precisioncountertops.moraware.net/sys/job/${row.jobid}`}
                >
                  {row.jobid}
                </a>{' '}
                - {new Date(row.sent).toLocaleString()}
                {row.status.match(/DeliveryFailed/) && <> (Delivery Failed)</>}
              </li>
            ))}
        </ul>
      </Card.Body>
    </Card>
  );
};

OvFailures.propTypes = {
  data: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object)
  })
};

export default OvFailures;
