import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
// import { runningProjects } from 'data/dashboard/default';
import RateChart from './RateChart';
import Intro from './Intro';
import { deliveryRateData as deliveryRateStartingData } from './data-delivery';
import { completionRateData as completiongRateStartingData } from './data-completion';
import { conversionRateData as conversionRateStartingData } from './data-conversion';
import { useApiContext } from 'si/api/ApiProvider';
import { useAuthContext } from 'si/auth/AuthProvider';
import Agents from './Agents';
import OvFailures from './OvFailures';

function roundToTenths(num, addPercent = false) {
  if (!num) return num;
  if (addPercent) {
    return `${Math.round(num * 10) / 10}%`;
  }
  return Math.round(num * 10) / 10;
}

function commify(num) {
  if (!num) return 'n/a';
  if (num < 1000) return num;
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function removeTestData(rateData) {
  const newRateData = {
    ...rateData,
    tabs: rateData.tabs.map(tab => ({
      ...tab,
      summary: '...',
      detail: '...',
      rows: tab.rows.map(row => ({
        ...row,
        percentage: 0,
        details: '...'
      }))
    }))
  };
  return newRateData;
}

const queryTypes = [
  {
    type: 'conversions',
    name: 'last 24 hours total',
    options: {
      sent: "sent > datetime('now' , '-24 hours')"
    }
  },
  {
    type: 'conversions',
    name: 'last 24 hours by sequence',
    options: {
      groupBySequence: true,
      sent: "sent > datetime('now' , '-24 hours')"
    }
  },
  {
    type: 'conversions',
    name: 'last 30 days total',
    options: {
      sent: "sent > datetime('now' , '-30 days')"
    }
  },
  {
    type: 'conversions',
    name: 'last 30 days by sequence',
    options: {
      groupBySequence: true,
      sent: "sent > datetime('now' , '-30 days')"
    }
  },
  {
    type: 'conversions',
    name: 'since 10/1/23 total',
    options: {}
  },
  {
    type: 'conversions',
    name: 'since 10/1/23 by sequence',
    options: {
      groupBySequence: true
    }
  },
  {
    type: 'ops',
    name: 'all ops rows',
    options: {}
  },
  {
    type: 'lastTextSent',
    name: 'last text sent of any kind',
    options: {}
  },
  {
    type: 'agents',
    name: 'agents and their counts of completed',
    options: {}
  },
  {
    type: 'ovFailures',
    name: 'Order Verified activities that were not completed by apc',
    options: {}
  }
];

const Default = () => {
  const [deliveryRateData, setDeliveryRateData] = useState(
    deliveryRateStartingData
  );
  const [completionRateData, setCompletionRateData] = useState(
    completiongRateStartingData
  );
  const [conversionRateData, setConversionRateData] = useState(
    conversionRateStartingData
  );

  const [lastUpdated, setLastUpdated] = useState();
  const [lastTextSent, setLastTextSent] = useState();

  const [agents, setAgents] = useState();
  const [ovFailures, setOvFailures] = useState();

  const { queryReporting } = useApiContext();
  const { jwt } = useAuthContext();

  useEffect(() => {
    setDeliveryRateData(removeTestData(deliveryRateData));
    setCompletionRateData(removeTestData(completionRateData));
    setConversionRateData(removeTestData(conversionRateData));
  }, []);

  useEffect(() => {
    if (!jwt) return;
    doQueryReporting();
  }, [jwt]);

  async function doQueryReporting() {
    const results = await queryReporting(queryTypes);
    if (results.error) {
      console.log('error querying reporting', results);
    } else if (results.length !== queryTypes.length) {
      console.log('ERROR - queryReporting returned incorrect length', results);
    } else {
      console.log('SUCCESS - queryReporting results', results);
      translateFetchedData(results);
    }
  }

  function translateFetchedData(data) {
    // starting with an array of objects, returned from service

    // get ops data
    const ops = data.filter(item => item.type === 'ops')[0].results;
    setLastUpdated(
      ops.find(r => (r.type = 'reporting-seed-database')).timestamp
    );

    // get lastTextSent data
    setLastTextSent(
      data.filter(item => item.type === 'lastTextSent')[0].results
    );

    // get agents
    setAgents(data.filter(item => item.type === 'agents')[0].results);

    // get ovFailures
    setOvFailures(data.filter(item => item.type === 'ovFailures')[0].results);

    // get conversions data
    // turn it into an object, keyed on name

    // get conversion data
    const conversions = data.filter(item => item.type === 'conversions');

    // get totals
    const totals = conversions
      .filter(item => item.name && item.name.match(/total/i))
      .map(item => item.results[0]);

    // get sequences
    const sequences = conversions
      .filter(item => item.name && !item.name.match(/total/i))
      // first key by sequence name
      .map(item =>
        item.results.reduce((acc, item) => {
          acc[item.sequence] = item;
          return acc;
        }, {})
      )
      // now put in explicit order
      .map(item => [
        item['Welcome Text'],
        item['Order Verified'],
        item['Confirm Template'],
        item['Confirm Install'],
        item['VOC']
      ]);

    // apply to deliveryRateData
    const newDeliveryRateData = {
      ...deliveryRateData,
      tabs: deliveryRateData.tabs.map((tab, index) => ({
        ...tab,
        summary: roundToTenths(totals[index]?.deliveryRate, true),
        detail: `${commify(totals[index]?.countDelivered)} of ${commify(
          totals[index]?.countSent
        )}`,
        rows: tab.rows.map((row, i) => ({
          ...row,
          percentage: roundToTenths(sequences[index][i]?.deliveryRate),
          details: `${commify(
            sequences[index][i]?.countDelivered
          )} of ${commify(sequences[index][i]?.countSent)}`
        }))
      }))
    };
    setDeliveryRateData(newDeliveryRateData);

    // apply to completionRateData
    const newCompletionRateData = {
      ...completionRateData,
      tabs: completionRateData.tabs.map((tab, index) => ({
        ...tab,
        summary: roundToTenths(totals[index]?.apcCompletionRate, true),
        detail: `${commify(totals[index]?.countCompletedByApc)} of ${commify(
          totals[index]?.countActivityCompleteOrCancelled
        )}`,
        rows: tab.rows.map((row, i) => ({
          ...row,
          percentage: roundToTenths(sequences[index][i]?.apcCompletionRate),
          details: `${commify(
            sequences[index][i]?.countCompletedByApc
          )} of ${commify(
            sequences[index][i]?.countActivityCompleteOrCancelled
          )}`
        }))
      }))
    };
    setCompletionRateData(newCompletionRateData);

    // apply to conversionRateData
    const newConversionRateData = {
      ...conversionRateData,
      tabs: conversionRateData.tabs.map((tab, index) => ({
        ...tab,
        summary: roundToTenths(totals[index]?.apcConversionRate, true),
        detail: `${commify(
          totals[index]?.countCompletedByHomeowner
        )} of ${commify(totals[index]?.countActivityCompleteOrCancelled)}`,
        rows: tab.rows.map((row, i) => ({
          ...row,
          percentage: roundToTenths(sequences[index][i]?.apcConversionRate),
          details: `${commify(
            sequences[index][i]?.countCompletedByHomeowner
          )} of ${commify(
            sequences[index][i]?.countActivityCompleteOrCancelled
          )}`
        }))
      }))
    };
    setConversionRateData(newConversionRateData);
  }

  return (
    <>
      <Row className="g-3 mb-3">
        <Col lg={6} className="">
          <Intro data={{ lastTextSent, lastUpdated }} />
        </Col>
        <Col lg={6}>
          <RateChart data={deliveryRateData} />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col lg={6}>
          <RateChart data={completionRateData} />
        </Col>
        <Col lg={6}>
          <RateChart data={conversionRateData} />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col className="">
          <OvFailures data={{ rows: ovFailures }} />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col lg={6} className="">
          <Agents data={{ agents }} />
        </Col>
      </Row>
    </>
  );
};

export default Default;
