import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
// import UserTableHeader from './UserTableHeader';
import IconButton from 'components/common/IconButton';
// import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useApiContext } from 'si/api/ApiProvider';
import { useAuthContext } from 'si/auth/AuthProvider';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { Link, useNavigate } from 'react-router-dom';

const pageSize = 100;

const ThingList = ({ thingSpec }) => {
  const [thingData, setThingData] = useState([]);
  const [loadingList, setLoadingList] = useState(true);

  // TODO: move these!
  const [key] = useState(''); // TODO: move this to property list
  const [loadingSave] = useState(false); // TODO: move to property list
  const [loadingDelete] = useState(false); // TODO: move to property list

  const { getThings } = useApiContext();
  const { jwt, canDo } = useAuthContext();

  const navigate = useNavigate();

  async function fetchThings() {
    setLoadingList(true);
    const { singular: type } = thingSpec;
    const result2 = await getThings(type);
    console.log('RESULT2', result2);
    setLoadingList(false);
    if (result2.error) {
      console.log(`error getting things`, result2);
    } else {
      console.log(`got things`, result2);
      setThingData(result2.items.map(i => ({ id: i.id, ...i.data })));
    }
  }
  useEffect(() => {
    if (!jwt) return;
    fetchThings();
  }, [jwt, thingSpec]);

  function newThing(e) {
    // console.log('newThing', e);
    e.preventDefault();
    navigate(`/config/${thingSpec?.plural}/new`);
  }

  // // this is the sorting for table v8 (but we're currently on v7)
  // const sorting = [
  //   {
  //     id: thingSpec?.listAttributes[0], // Replace with the ID of the column you want to sort
  //     desc: true // Set to `true` for descending order, or `false` for ascending
  //   }
  // ];

  // this is the sorting for v7
  const sortBy = React.useMemo(
    () => [
      {
        id: thingSpec?.listAttributes[0],
        desc: false
      }
    ],
    []
  );

  const { listAttributes } = thingSpec;
  const listColumns = listAttributes.map(a => ({
    accessor: a,
    Header: thingSpec?.attributes[a]?.label,
    headerProps: { className: 'pe-1 text-900' }
  }));

  const columns = [
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: ''
      },
      Cell: rowData => {
        return loadingSave && key === rowData.row.original.email ? (
          <Spinner animation="border" role="status" size="sm">
            <span className="visually-hidden">Saving...</span>
          </Spinner>
        ) : (
          <Link to={`./../${thingSpec?.singular}/${rowData.row.original.id}`}>
            <FontAwesomeIcon icon={faEye} className="" />
          </Link>
        );
      }
    },
    ...listColumns
  ];

  return (
    <>
      {loadingList && (
        <Card>
          <Card.Header></Card.Header>
          <Card.Body className="p-0">
            <div className="text-center mt-4">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
            <div>
              <p className="text-center mt-5 mb-4">
                Loading {thingSpec.labelPlural}...
              </p>
            </div>
          </Card.Body>
          <Card.Footer></Card.Footer>
        </Card>
      )}
      {thingData && !loadingList && (
        <AdvanceTableWrapper
          columns={columns}
          data={thingData}
          // selection
          sortable
          sortBy={sortBy}
          pagination
          perPage={pageSize}
          rowCount={thingData.length}
        >
          <Card>
            <Card.Header>
              <Row className="flex-between-center flex-column flex-sm-row">
                <Col sm="auto" className="d-flex align-items-center pe-0">
                  <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                    <span style={{ textTransform: 'capitalize' }}>
                      {thingSpec.labelPlural}
                    </span>
                  </h5>
                  {loadingDelete ? (
                    <>
                      <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                        className="mx-2"
                      >
                        <span className="visually-hidden">Deleting...</span>
                      </Spinner>
                      <span className="fs--1">
                        Deleting {thingSpec.labelSingular}
                      </span>
                    </>
                  ) : (
                    ''
                  )}
                </Col>
                <Col sm="auto" className="">
                  <div id="orders-actions">
                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon="plus"
                      transform="shrink-3"
                      onClick={newThing}
                      disabled={!canDo(thingSpec.right)}
                    >
                      <span className="ms-1">
                        New{' '}
                        <span style={{ textTransform: 'capitalize' }}>
                          {thingSpec.labelSingular}
                        </span>
                      </span>
                    </IconButton>
                  </div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-nowrap align-middle"
                rowClassName="btn-reveal-trigger text-nowrap align-middle"
                tableProps={{
                  size: 'sm',
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTableFooter
                rowCount={thingData.length}
                table
                rowInfo={thingData.length > pageSize}
                navButtons={thingData.length > pageSize}
              />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      )}
    </>
  );
};

ThingList.propTypes = {
  thingSpec: PropTypes.object
};

export default ThingList;
