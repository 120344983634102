import classNames from 'classnames';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Badge,
  Card,
  Col,
  ProgressBar,
  Row,
  Tab,
  Nav,
  Spinner
} from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';

const TabTitle = ({ title, summary, detail }) => (
  <div className="p-2 pe-4 text-start cursor-pointer">
    <h6 className="text-800 fs--2 text-nowrap">{title}</h6>
    <h5 className="text-800">{summary}</h5>
    <Flex alignItems="center">
      <h6 className={`fs--2 mb-0 text-primary`}>{detail}</h6>
    </Flex>
  </div>
);

TabTitle.propTypes = {
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  detail: PropTypes.string.isRequired
};

const Project = ({ project, isLast }) => {
  const { color, percentage, details, title } = project;
  return (
    <Row
      className={classNames('position-relative align-items-center py-2', {
        'border-bottom border-200 ': !isLast
      })}
    >
      <Col className="py-1">
        <Flex className="align-items-center">
          <div className="avatar avatar-xl me-3">
            <div className={`avatar-name rounded-circle bg-${color}-subtle`}>
              <span className={`fs-0 text-${color.split('-')[1] || color}`}>
                {title[0]}
              </span>
            </div>
          </div>
          <Flex tag="h6" className="mb-0 align-items-center">
            <a className="text-800 stretched-link" href="">
              {title}
            </a>
            <Badge pill bg="200" className="ms-2 text-primary">
              {percentage}%
            </Badge>
          </Flex>
        </Flex>
      </Col>
      <Col>
        <Row className="justify-content-end align-items-center">
          <Col xs="auto pe-0">
            <div className="fs--1 fw-semi-bold">{details}</div>
          </Col>
          <Col xs="5" className="pe-x1">
            <ProgressBar now={percentage} style={{ height: 5 }} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

Project.propTypes = {
  project: PropTypes.shape({
    color: PropTypes.string.isRequired,
    percentage: PropTypes.number.isRequired,
    details: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }),
  isLast: PropTypes.bool
};

const ProjectChart = ({ data }) => {
  return (
    <>
      {data.map((project, index) => (
        <Project
          project={project}
          isLast={index === data.length - 1}
          isFirst={index === 0}
          key={index}
        />
      ))}
    </>
  );
};

ProjectChart.propTypes = {
  data: PropTypes.arrayOf(Project.propTypes.project).isRequired
};

const RateChart = ({ data }) => {
  function isLoading() {
    return data.tabs[0].summary === '...';
  }

  return (
    <Card className="h-100">
      <FalconCardHeader
        title={
          <>
            {data.title}
            {isLoading() && (
              <>
                <Spinner
                  animation="border"
                  role="status"
                  size="sm"
                  className="ms-3 text-primary"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>{' '}
                <span
                  className="fs--1 text-secondary ms-1"
                  style={{ fontWeight: 'normal' }}
                >
                  loading ...
                </span>
              </>
            )}
          </>
        }
        light
        titleTag="h5"
        className="mb-0 pb-0"
      />
      <Card.Footer
        className="bg-100 fs--1 pt-1 pb-2"
        style={{ fontStyle: 'italic' }}
      >
        {data.summary}
      </Card.Footer>
      <Tab.Container id="audience-tab" defaultActiveKey={data.tabs[0].title}>
        <SimpleBarReact>
          <Card.Header className="p-0 bg-body-tertiary">
            <Nav className="nav-tabs border-0 flex-nowrap chart-tab">
              {data.tabs.map((tab, index) => (
                <Nav.Item key={index}>
                  <Nav.Link className="mb-0" eventKey={tab.title}>
                    <TabTitle
                      title={tab.title}
                      summary={tab.summary}
                      detail={tab.detail}
                    />
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Card.Header>
        </SimpleBarReact>

        <Card.Body className="pb-2">
          <Tab.Content>
            {data.tabs.map((tab, index) => (
              <Tab.Pane
                unmountOnExit
                eventKey={tab.title}
                key={index}
                className="p-0"
              >
                <ProjectChart data={data.tabs[index].rows} />
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Card.Body>
      </Tab.Container>

      <Card.Footer className="bg-100 fs--1">{data.footer}</Card.Footer>
    </Card>
  );
};

RateChart.propTypes = {
  data: PropTypes.object.isRequired
};

export default RateChart;
