export const deliveryRateData = {
  title: 'Delivery Rate',
  summary: 'Of texts sent, how many were delivered successfully?',
  footer:
    'NOTE: only first text of each sequence is included in delivery rate.',
  tabs: [
    {
      title: 'Last 24 Hours',
      summary: '94.0%',
      detail: '137 of 157',
      rows: [
        {
          title: 'Welcome',
          percentage: 60.1,
          details: '61 of 101',
          color: 'primary'
        },
        {
          title: 'Order Verified',
          percentage: 78,
          details: '70 of 93',
          color: 'success'
        },
        {
          title: 'Template',
          percentage: 79,
          details: '25:20:00',
          color: 'info'
        },
        {
          title: 'Install',
          percentage: 38,
          details: '12:50:00',
          color: 'warning'
        },
        {
          title: 'VOC',
          percentage: 40,
          details: '21:20:00',
          color: 'danger'
        }
      ]
    },
    {
      title: 'Last 30 Days',
      summary: '95.3%',
      detail: '987 of 1,036',
      rows: [
        {
          title: 'Welcome',
          percentage: 62.1,
          details: '62 of 101',
          color: 'primary'
        },
        {
          title: 'Order Verified',
          percentage: 78,
          details: '70 of 93',
          color: 'success'
        },
        {
          title: 'Template',
          percentage: 79,
          details: '25:20:00',
          color: 'info'
        },
        {
          title: 'Install',
          percentage: 38,
          details: '12:50:00',
          color: 'warning'
        },
        {
          title: 'VOC',
          percentage: 40,
          details: '21:20:00',
          color: 'danger'
        }
      ]
    },
    {
      title: 'Since 10/1/23',
      summary: '93.5%',
      detail: '4,567 of 5,678',
      rows: [
        {
          title: 'Welcome',
          percentage: 63.1,
          details: '63 of 101',
          color: 'primary'
        },
        {
          title: 'Order Verified',
          percentage: 78,
          details: '70 of 93',
          color: 'success'
        },
        {
          title: 'Template',
          percentage: 79,
          details: '25:20:00',
          color: 'info'
        },
        {
          title: 'Install',
          percentage: 38,
          details: '12:50:00',
          color: 'warning'
        },
        {
          title: 'VOC',
          percentage: 40,
          details: '21:20:00',
          color: 'danger'
        }
      ]
    }
  ]
};
