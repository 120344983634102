import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FalconCardHeader from 'components/common/FalconCardHeader';

const Agents = ({ data }) => {
  return (
    <Card className="h-100">
      <FalconCardHeader
        title="Agents"
        light
        titleTag="h5"
        className="mb-0 pb-0"
      />
      <Card.Footer
        className="bg-100 fs--1 pt-1 pb-2"
        style={{ fontStyle: 'italic' }}
      >
        Agents who have completed at least one text for a homeowner (followed by
        how many they've completed)
      </Card.Footer>

      <Card.Body className="overflow-hidden p-lg-3">
        <ul>
          {data.agents &&
            data.agents.map((agent, index) => (
              <li key={index}>
                {agent.agent} ({agent.countComplete})
              </li>
            ))}
        </ul>
      </Card.Body>
    </Card>
  );
};

Agents.propTypes = {
  data: PropTypes.shape({
    agents: PropTypes.arrayOf(PropTypes.object)
  })
};

export default Agents;
