import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Spinner, Form } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { useApiContext } from 'si/api/ApiProvider';
import { useNavigate } from 'react-router-dom';
import { TooltipLabel } from 'si/components/common/TooltipLabel';
import { ThingInput } from './ThingInput';

const ThingNew = ({ thingSpec, type }) => {
  const { attributes } = thingSpec;

  // set up state
  const [thingData, setThingData] = useState({});
  const [loadingOne, setLoadingOne] = useState(false);

  const { saveThing } = useApiContext();

  const navigate = useNavigate();

  async function doSave() {
    setLoadingOne(true);
    await saveThing(type, thingData?.id, thingData);
    setLoadingOne(false);
    // TODO - make this relative, so that it works for any type
    // alternatively, add the base url to the thingSpec (might be easier to understand)
    navigate(`/config/${type}/${thingData?.id}`);
  }

  function saveUiThing(e) {
    console.log('saveThing', e);
    e.preventDefault();
    doSave();
    // console.log({ thingData });
  }

  return (
    <Card>
      <Card.Header>
        <Row className="flex-between-center flex-column flex-sm-row">
          <Col sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              <span style={{ textTransform: 'capitalize' }}>
                {thingSpec.labelSingular} Details
                {loadingOne && (
                  <Spinner
                    animation="border"
                    role="status"
                    size="sm"
                    className="mx-3 text-primary"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                )}
              </span>
            </h5>
          </Col>
          <Col sm="auto" className="">
            <div id="orders-actions">
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="cloud"
                transform="shrink-3"
                onClick={saveUiThing}
                disabled={loadingOne || !thingData?.id}
              >
                <span className="ms-1 text-primary">
                  Save{' '}
                  <span style={{ textTransform: 'capitalize' }}>
                    {thingSpec.labelSingular}
                  </span>
                </span>
              </IconButton>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="overflow-hidden p-lg-6">
        {Object.keys(attributes).map(attributeName => {
          const attribute = attributes[attributeName];
          return (
            <Row key={attributeName} className="">
              <Form.Group>
                <Col className="">
                  <TooltipLabel
                    attributeName={attributeName}
                    attribute={attribute}
                  />
                </Col>
                <Col className="">
                  <div className="mb-3" style={attribute.editStyle}>
                    <ThingInput
                      attributeName={attributeName}
                      attribute={attribute}
                      thingData={thingData}
                      setThingData={setThingData}
                      newItem={true}
                    />
                  </div>
                </Col>
              </Form.Group>
            </Row>
          );
        })}
      </Card.Body>
    </Card>
  );
};

ThingNew.propTypes = {
  thingSpec: PropTypes.object,
  type: PropTypes.string
};

export default ThingNew;
